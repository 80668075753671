<template>
    <div></div>
</template>

<script>
  export default {
    name: 'RefreshRouter',
    data () {
      return {
        fromPath: ''
      }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        // 通过 `vm` 访问组件实例,将值传入fromPath
        vm.fromPath = from.path
      })
    },
    mounted () {
      this.$nextTick(() => {
        // 验证是否获取到了上页的url
        /* eslint-disable no-console */
        this.$router.push(this.fromPath).then()
      })
    }
  }
</script>

<style scoped>

</style>
